<template>
	<div class="musiccategory">
		<section class="catrgory bg-grey">
			<div class="container">
				<div class="category-title">
					<h5>Top Latin Billboard Hits </h5>
					<router-link to="/allmusiccategory"><p>View All</p></router-link>
				</div>

				<div class="row">
					<div class="col-sm-12 col-lg-3 col-md-4" v-for="value in musiccategory" :key="value.songcategory_id">
						<router-link :to="{name: 'CategoryMusic', params:{categoryId: value.songcategory_id}}">
							<div class="cate-box">
								<div class="loader-static">
									<div class="loader-dynamic">
										<img :src="value.image">
									</div>
									<img src="assets/images/category/transperent.png" class="stactic-img">
								</div>
								<div class="hover-box">
									<h6>{{ value.name }}</h6>
								</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "MusicCategory",
		props:['musiccategory']
	}
</script>