<template>
	<!-- banner slider secction -->
	<section class="banner-slider music-section">
		<carousel :per-page="1" :mouse-drag="true" :loop="true" :autoplay="true" :autoplayTimeout="5000" :speed="500" :navigationEnabled="true">
			<slide v-for="slider in homeSlider" :key="slider.homeslider_id">
				<img :src="slider.image">
			</slide>
		</carousel>
	</section>
	<!-- end banner slider section -->
</template>
<script>
	export default {
		name: "MusicBanner",
		props: ['homeSlider']
	}
</script>