<template>
	<div class="allmusiccategory">
		<loader v-if="loading"></loader>
		<section class="playlist top-spacing bg-grey">
			<div class="same-space pt-4 mt-4 pb-5 ">
				<div class="container">
					<div class="main-heading pb-3">
						<div class="pattern">
							<img src="assets/images/Design-Square-Ellipse.png">
						</div>
						<h1 class="title-section pt-0 pb-2">
							Top Latin Billboard Hits 
						</h1>
					</div>
					<div class="row" v-if="songs_category.length > 0">
						<div class="col-sm-12 col-lg-3 col-md-4" v-for="value in songs_category" :key="value.menu_id">
							<router-link :to="{name: 'AllMusicCategory', params:{menuId: value.menu_id}}">
								<div class="cate-box">
									<div class="loader-static">
										<div class="loader-dynamic">
											<img :src="value.menu_image">
										</div>
										<img src="assets/images/category/transperent.png" class="stactic-img">
									</div>
									<div class="hover-box">
										<h6>{{ value.menu_name }}</h6>
									</div>
								</div>
							</router-link>
						</div>
					</div>
					<div class="nodata" v-else>
						<h1>No Records Found</h1>
					</div>
					<br>
					<div class="number-of" v-if="songs_category.length > 0">
						<button class="btn btn-primary btn-sm" :disabled="current_page === 1" @click="prevCategory">Prev</button>
						{{ current_page }}
						<button class="btn btn-primary btn-sm" :disabled="current_page === pages" @click="nextCategory">Next</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	export default {
		name: "AllCategory",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				songs_category: null,
				current_page: 1,
				pages: null,
				limit: 20,
				loading: false
			}
		},
		methods: {
			nextCategory() {
				this.current_page = (this.pages > this.current_page) ? parseInt(this.current_page) + 1 : this.current_page;
				this.getAllMusicCategory();
			},
			prevCategory() {
				this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : this.current_page;
				this.getAllMusicCategory();
			},
			getAllMusicCategory() {
				this.loading = true;
				HTTP.get(`/menu?limit=${this.limit}&page=${this.current_page}`)
					.then(res => {
						if(res.data.status === 200){
							this.songs_category = res.data.data;
							this.current_page = res.data.current_page;
							this.pages = res.data.last_page;
							this.loading = false;
						}else{
							this.$swal.fire({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
								icon: 'warning',
								title: res.data.message
							});
							this.loading = false;
						}
					}).catch(err => {
						this.loading = false;
						console.log(err)
					});
			}
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getAllMusicCategory();
		}
	}
</script>