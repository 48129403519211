<template>
	<div class="contactus">
		<loader v-if="loading"></loader>
		<!-- contact-us--section -->
		<section class="contact-us " id="contact-us">
			<div class="grren-box">
			</div>
			<div class="same-space">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-lg-6 col-md-6">
							<div class="content-wrapper">
								<div class="pattern">
									<img src="assets/images/Design-Square-Ellipse.png">
								</div>
								<h1 class="title-section">
									Contact Us
								</h1>
								<div class="contact-form">
									<form @submit.prevent="sendContactRequest">
										<div class="form-group">
											<input type="text" class="from-control" placeholder="Name" v-model="name" required>
										</div>
										<div class="form-group">
											<input type="email" class="from-control" placeholder="Email" v-model="email" required>
										</div>
										<div class="form-group">
											<input type="text" class="from-control" placeholder="Message" v-model="message" required>
										</div>
										<button type="submit" class="section-btn btn-lg">
											Send
										</button>
									</form>
								</div>

							</div>
						</div>

						<div class="col-sm-12 col-lg-6 col-md-6">
							<div class="main-wrapper-box">
								<div class="white-box"></div>
								<div class="greay-box">

									<div class="contact-us-wrapper">

										<ul>

											<h5>Info</h5>

											<li><a :href="'mailto:'+contactus.email"><span><i class="fal fa-envelope"></i></span> 
												<p>{{ contactus.email }}</p></a>
											</li>
											<li><a :href="contactus.website" target="blank"><span><i class="fal fa-globe"></i></span> 
												<p>{{ contactus.website }}</p></a>
											</li>
											<li><a :href="contactus.phone" target="blank"><span><i class="fal fa-phone-alt"></i></span> 
												<p>{{ contactus.phone }}</p></a>
											</li>
											<li><a :href="contactus.address" target="blank"><span><i class="fas fa-map-marker-alt"></i></span> 
												<p>{{contactus.address}}</p></a>
											</li>
										</ul>

									</div>

								</div>
							</div>
						</div>


					</div>

				</div>
			</div>

		</section>
		<!-- end section -->
	</div>
</template>
<script>
	import { HTTP } from '../../_helper/http-constants.js';
	export default {
		name: "ContactUs",
		data(){
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				contactus: '',
				name: '',
				email: '',
				message: '',
				loading: false
			}
		},
		methods: {
			sendContactRequest(){
				this.loading = true;
				HTTP.post('/contactrequest',{
					name: this.name,
					email: this.email,
					message: this.message
				}).then(res => {
					if(res.data.status === 200){
						this.name = "";
						this.email = "";
						this.message = "";
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			getContactUsData(){
				this.loading = true;
				HTTP.get('/cms').then(res => {
					if(res.data.status === 200){
						this.contactus = res.data.contactus_data[0];
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			}
		},
		mounted(){
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getContactUsData();
		}
	}
</script>