<template>
	<div>
	<header class="wrapper-header">
		<loader v-if="loading"></loader>
		<!-- header section -->
		<nav class="theme-dark header">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 col-md-2 ">
						<div class="logo img-fluid">
							<router-link to="/" title="Home"><img :src="logo.image"></router-link>
						</div>
					</div>
					<div class="col-lg-10 col-md-10 display-center">
						<div class="nav-link header-link">
							<ul>
								<li :class="[$route.name == 'Home' ? 'active' : '']">
									<router-link to="/">Home</router-link>
								</li>
								<li :class="[$route.name == 'LiveRadio' ? 'active' : '']">
									<router-link to="/liveradio">Live Radio</router-link>
								</li>
								<li :class="[$route.name == 'LiveVideo' ? 'active' : '']">
									<router-link to="/livevideo">Live Stream</router-link>
								</li>
								<li :class="[$route.name == 'Program' ? 'active' : '']">
									<router-link to="/program">Daily Shows</router-link>
								</li>
								<li :class="[$route.name == 'Liveevent' ? 'active' : '']">
									<router-link to="/liveevent">Live Event</router-link>
								</li>
								<li :class="[$route.name == 'AllMusicCategory' || $route.name == 'CategoryMusic' ? 'active' : '']">
									<router-link to="/allcategory">Music Categories</router-link>
								</li>
								<li :class="[$route.name == 'Favourites' ? 'active' : '']">
									<router-link to="/favourites">My Favorites</router-link>
								</li>
								<li :class="[$route.name == 'Playlist' || $route.name == 'PlaylistSongs' ? 'active' : '']">
									<router-link to="/playlist">My Playlist</router-link>
								</li>
								<li :class="[$route.name == 'ContactUs' ? 'active' : '']">
									<router-link to="/contactus">Contact Us</router-link>
								</li>
							</ul>
						</div>
						<div class="wrapper-end-btn mobile-btn">
							<div class="search">
								<span><i class="fal fa-search"></i></span>
							</div>
							<div class="mobile-icon">
								<span><i class="fas fa-bars"></i></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<!-- End header section -->

		<!-- mobile siderbar -->
		<div class="mobile-menu">
			<div class="siderbar-mobile">
				<div class="close-menu">
					<i class="fal fa-times"></i>
				</div>
				<div class="mobile-link">
					<ul>
						<li :class="[$route.name == 'Home' ? 'active' : '']">
							<router-link to="/">Home</router-link>
						</li>
						<li :class="[$route.name == 'LiveRadio' ? 'active' : '']">
							<router-link to="/liveradio">Live Radio</router-link>
						</li>
						<li :class="[$route.name == 'LiveVideo' ? 'active' : '']">
							<router-link to="/livevideo">Live Video</router-link>
						</li>
						<li :class="[$route.name == 'Program' ? 'active' : '']">
							<router-link to="/program">Daily Shows</router-link>
						</li>
						<li :class="[$route.name == 'Liveevent' ? 'active' : '']">
									<router-link to="/liveevent">Live Event</router-link>
						</li>
						<li :class="[$route.name == 'AllMusicCategory' || $route.name == 'CategoryMusic' ? 'active' : '']">
							<router-link to="/allmusiccategory">Music Categories</router-link>
						</li>
						<li :class="[$route.name == 'Favourites' ? 'active' : '']">
							<router-link to="/favourites">My Favorites</router-link>
						</li>
						<li :class="[$route.name == 'Playlist' || $route.name == 'PlaylistSongs' ? 'active' : '']">
							<router-link to="/playlist">My Playlist</router-link>
						</li>
						<li :class="[$route.name == 'ContactUs' ? 'active' : '']">
							<router-link to="/contactus">Contact Us</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="overlay"></div>
		</div>
	</header>
	<div class="home top-spacing bg-grey">
		<SponsorBanner :sponsorBanner="sponsor_banner"></SponsorBanner>
	</div>	
	</div>
</template>
<script>
import { HTTP } from '../../_helper/http-constants.js';
import SponsorBanner from './../home/sponsorbanner.vue'; 
export default {
	name: 'headernav',
	components: {
			SponsorBanner
		},
	data(){
		return {
			user: JSON.parse(window.localStorage.getItem('user')),
			logo: '',
			loading: false,
			sponsor_banner: null
		}
	},
	methods: {
		getHeaderData(){
			this.loading = true;
			HTTP.get('/cms').then(res => {
				if(res.data.status === 200){
					this.logo = res.data.logo_data[0];
					this.sponsor_banner = res.data.sponsorbanner_data;
					this.loading = false;
				}else{
					this.loading = false;
					this.$swal.fire({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						icon: 'warning',
						title: res.data.message
					});
				}
			}).catch(err => {
				this.loading = false;
				console.log(err);
			});
		}
	},
	mounted(){
		this.user = JSON.parse(window.localStorage.getItem('user'));
		this.getHeaderData();
	}
}
</script>