<template>
  <div class="allmusiccategory">
    <loader v-if="loading"></loader>
    <section class="playlist top-spacing bg-grey">
      <div class="same-space pt-4 mt-4 pb-5 ">
        <div class="container">
          <div class="main-heading pb-3">
            <div class="pattern">
              <img src="assets/images/Design-Square-Ellipse.png">
            </div>
            <h1 class="title-section pt-0 pb-2"> Daily Shows </h1>
          </div>
          <div class="gride-container" v-if="program.length > 0">
            <div class="gride-box" v-for="value in program" :key="value.event_id">
                <div class="loader-static">
                    <div class="loader-dynamic">
                        <img :src="value.event_image"  @click="openpop(value.event_id)">
                    </div>
                    <img src="assets/images/trans300.jpeg" class="stactic-img">
                </div>
                <div class="gride-content" style="background:#100c08; height:100%;">
                    <h6 style="color:white;"><b>{{ value.event_name }}</b></h6>
                    <p  style="color:white;"> {{ value.event_days }} </p>
                    <span style="color: white;font-size: 12px;">{{ value.event_start_time }}  </span><span style="color: white;font-size: 12px; margin-left:4px;"> -  {{ value.event_end_time }}</span>
                </div>
                <div class="model-open" :id="'popbox_'+value.event_id" style="display:none;" >
                  <span style="cursor:pointer" class="clsclick"  @click="closepop()">X</span>
                  <div class="img_mdl">
                    <img :src="value.event_image">
                  </div>
                  <div class="desc_title">
                      
                      <h6 style="color:white;"><b>{{ value.event_name }}</b></h6>
                      <p  style="color:white;"> {{ value.event_days }} </p>
                      <span style="color: white;font-size: 12px;">{{ value.event_start_time }}  </span><span style="color: white;font-size: 12px; margin-left:4px;"> -  {{ value.event_end_time }}</span>		
                      <p class="ptx">{{value.event_description}}</p>	
                  </div>
                </div>
            </div>
            <!--<div class="col-sm-12 col-lg-3 col-md-4" v-for="value in program" :key="value.event_id">
              <div class="cate-box">
                <div class="loader-static">
                  <div class="loader-dynamic">
                    <img :src="value.event_image">
                  </div>
                  <img src="assets/images/category/transperent.png" class="stactic-img">
                </div>
                <div class="hover-box">
                  <h6>{{ value.event_name }}</h6>
                </div>
              </div>
              <div class="hover-box">
                <h6>{{ value.event_days }}</h6>
              </div>
              <div class="hover-box">
                <h6>{{ value.event_start_time }}</h6>
              </div>
              <div class="hover-box">
                <h6>{{ value.event_end_time }}</h6>
              </div>
            </div>-->
          </div>
          <div class="nodata" v-else>
            <h1>No Records Found</h1>
          </div>
          <br>
        </div>
      </div>
    </section>
    <div class="overlay_black" id="ov_black"></div>	
  </div>
</template>
<script>
  import {
    HTTP
  } from '../../_helper/http-constants.js';
  export default {
    name: "Program",
    data() {
      return {
        user: JSON.parse(window.localStorage.getItem('user')),
        program: null,
        current_page: 1,
        pages: null,
        limit: 20,
        loading: false
      }
    },
    methods: {
      nextCategory() {
        this.current_page = (this.pages > this.current_page) ? parseInt(this.current_page) + 1 : this.current_page;
        this.getAllMusicCategory();
      },
      prevCategory() {
        this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : this.current_page;
        this.getAllMusicCategory();
      },
      openpop(popid){
				//alert(popid);
				document.getElementById("ov_black").style.display = "block";
				document.getElementById("popbox_"+popid).style.display = "block";
			},
			closepop(){
				//alert("in");
				//document.getElementsByClassName("model-open").style.display = 'none';
				var elems = document.getElementsByClassName('model-open');
					for (var i=0;i<elems.length;i+=1){
					elems[i].style.display = 'none';
					}
				document.getElementById("ov_black").style.display = "none";	
			},
      getAllMusicCategory() {
        this.loading = true;
        HTTP.get(`/program`).then(res => {
          if (res.data.status === 200) {
            this.program = res.data.data;
            //this.current_page = res.data.current_page;
            //this.pages = res.data.last_page;
            this.loading = false;
          } else {
            this.$swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: 'warning',
              title: res.data.message
            });
            this.loading = false;
          }
        }).catch(err => {
          this.loading = false;
          console.log(err)
        });
      }
    },
    mounted() {
      this.user = JSON.parse(window.localStorage.getItem('user'));
      this.getAllMusicCategory();
    }
  }
</script>